<template>
    <div>
        <Editor apiKey="0g85j0efm0dz98h4le3448md0ug2fvfs7b9r60hj0udoz8bb" v-if="innerDrawer" :id="tinymceId" :init="init" :disabled="disabled" ></Editor>
    </div>
</template>
<script>
    //引入tinymce-vue
    import Editor from '@tinymce/tinymce-vue'
    import moment from 'moment';

    var requ = require("@/assets/js/tinymce-template");

    //公共的图片前缀
    export default {
        components: {
            Editor
            },
        props: {
            //编号
            id:{
                type:String
            },
            //内容
            value: {
                type: String,
                default: ''
            },
            //是否禁用
            disabled: {
                type: Boolean,
                default: false
            },
            tinymce_height: {
                type: String,
                default: "500"
            },
            innerDrawer: {
                type: Boolean,
                default: false
            },

        },
        data() {
            let that = this;
            moment.locale('zh-cn');
            return {
                title: '文章编辑器',
                tinymceId: this.id || 'vue-tinymce',
                init:{
                    selector: '#tinydemo',
                    setup: function(editor) {
                        console.log("ID为: " + editor.id + " 的编辑器即将初始化.");
                    },
                    //汉化,路径是自定义的，一般放在public或static里面，汉化文件要自己去下载
                    language_url: '/static/zh_CN.js',
                    language: 'zh_CN',
                    //皮肤
                    skin: 'oxide',
                    font_formats:"Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n;宋体=宋体;新宋体=新宋体;黑体=黑体;仿宋=仿宋;微软雅黑=微软雅黑;楷体_GB2312=楷体_GB2312",
                    plugins: "searchreplace paste bold italic forecolor backcolor underline strikethrough removeformat lineheight  alignleft aligncenter alignright alignjustify code indent2em indent styleselect fontselect fontsizeselect "+
                        "insertfile charmap numlist bullist hr "+
                        "code media link image imagetools axupimgs importword bdmap template wordcount autolink anchor preview print "+
                        "autolink ax_wordlimit formatpainter "+
                        "table insertdatetime toc fullscreen lists,advlist",

                    toolbar: "code searchreplace paste bold italic forecolor backcolor underline strikethrough removeformat lineheight alignleft aligncenter alignright alignjustify code indent2em indent styleselect fontselect fontsizeselect "+
                        "insertfile charmap numlist bullist hr "+
                        "code media link image axupimgs importword bdmap template wordcount autolink  anchor preview print "+
                        "autolink  "+
                        "table insertdatetime toc  fullscreen",
                    //隐藏右下角技术支持
                    branding: false,
                    //允许复制样式
                    paste_webkit_styles: "all",
                    //去掉文字间距
                    content_style: "p {margin: 0px; border:0px ; padding: 0px}",
                    //拖拽图片
                    paste_data_images: true,
                    //还原存储
                    autosave_restore_when_empty: false,
                    paste_enable_default_filters:false,
                    //高度
                    height: this.tinymce_height,
                    //为内容模板插件提供预置模板
                    templates: [],
                    // ax_wordlimit_num:200000,
                    // ax_wordlimit_callback: function(editor,txt,num){
                    //     alert('当前字数：' + txt.length + '，限制字数：' + num);
                    // },
                    //编辑器初始化结束后执行
                    init_instance_callback : function(editor) {
                        setTimeout(function () {
                            window.tinymce.activeEditor.setContent(that.value);
                            console.log("ID为: " + editor.id + " 的编辑器初始化完成.");
                        },1000)

                    },
                    //图片(单、多)上传
                    images_upload_handler: function (blobInfo,success,failure, progress) {
                        var blob = blobInfo.blob();//转化为易于理解的file对象
                        // blob转arrayBuffer
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(blob);
                        var xhr = new XMLHttpRequest();
                        xhr.upload.onprogress = function(e){
                            progress(e.loaded / e.total * 100);
                        }
                        // Blob转成File
                        let file = new File([blob], blob.name, { type: blob.type });
                        console.log(file);
                        that.utils.upload(file,function (url){
	                        if (!url){
		                        return false;
	                        }
                            console.log(url);
                            success(url.url);
                        })
                    },
                    //文件上传
                    file_picker_callback: function(callback, value, meta) {
                        //文件分类
                        var filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
                        console.log(meta.filetype)
                        //为不同插件指定文件类型及后端地址
                        switch(meta.filetype){
                            case 'image':
                                filetype='.jpg, .jpeg, .png, .gif';
                                break;
                            case 'file':
                                filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
                                break;
                            case 'media':
                                filetype='.mp3, .mp4';
                                break;
                            default:

                        }
                        //模拟出一个input用于添加本地文件
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', filetype);
                        input.click();
                        input.onchange = function() {
                            var file = this.files[0];
                            that.utils.upload(file,function (url){
	                            if (!url){
		                            return false;
	                            }
                                console.log(url);
                                callback(url.url);
                            })

                        };
                    },
                }
            }
        },
        mounted:function () {
        },
        created() {
            this.init.templates=requ.getjson();
        },
        methods:{
            get_content:function () {
                var content = window.tinymce.activeEditor.getContent();
                return content;
            },
            
            set_content:function (val) {
                setTimeout(function () {
                    window.tinymce.activeEditor.setContent(val)
                },1000);
            }
        },
        watch: {
            //监听内容变化
            // value(newValue) {
            //     this.myValue = newValue
            // },
            // myValue(newValue) {
            //     this.$emit('input', newValue)
            // }
        }
    }
</script>
